export enum ApplicationState {
  Initial = 'initial',
  RoomSelected = 'room_selected',
  Approved = 'approved',
  UserChangedPersonalData = 'user_changed_personal_data',
  RequestInfo = 'request_info',
  Denied = 'denied',
  Voided = 'voided',
  Finalized = 'finalized',
  ContractSigned = 'contract_signed',
  Error = 'error'
}

export default ApplicationState