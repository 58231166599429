import * as React from 'react'
import { useContext } from 'react'
import { AuthContext, AuthStatus, AuthInterface } from '../../contexts/authContext'

const AuthenticatedPage: React.FC = ({ children }) => {
  const { authStatus, setAuthFlowVisible }: AuthInterface = useContext(AuthContext)

  if (authStatus === AuthStatus.SignedOut) {
    setAuthFlowVisible(true)

    return <>Loading...</>
  }

  return <>{children}</>
}

export default AuthenticatedPage
