import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import Footer from '../components/footer'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import PageWrapper from '../components/page/page-wrapper'
import AuthenticatedPage from '../components/page/authenticated-page'
import { CmsRoomTypes, CmsTenant } from '../services/cms'
import { Application, Tenant, UpdateApplication } from '../../../../shared/types/cms'
import InfoIcon from '../images/icon-info.inline.svg'
import { usePopperTooltip } from 'react-popper-tooltip'
import DatePicker from '../components/datepicker'
import Alert, { AlertVariant } from '../components/alert'
import ApplicationState from '../../../../shared/types/ApplicationStateEnum'
import Modal from '../components/modal'
import LoadingButton from '../components/loading-button'
import { Helmet } from 'react-helmet'
import Header from '../components/header/header'
import { AuthContext } from '../contexts/authContext'

export const query = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        titleTemplate
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

interface SigningPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
      }
    }
  }
}

// Wrap the page to access the authcontext
export const SigningPage: React.FC<SigningPageProps> = (props) => {
  return (
    <PageWrapper>
      <AuthenticatedPage>
        <SigningPageInner {...props} />
      </AuthenticatedPage>
    </PageWrapper>
  )
}

export const SigningPageInner: React.FC<SigningPageProps> = (props) => {
  const { t } = useTranslation()
  const { navigate, language } = useI18next()
  const [application, setApplication] = React.useState<Application | null>(null)
  const [profile, setProfile] = React.useState<Tenant | null>(null)
  const [apartmentVisited, setApartmentVisited] = React.useState(false)
  const [visitDate, setVisitDate] = React.useState<string>()
  const [dataConfirmed, setDataConfirmed] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(``)
  const [successVisible, setSuccessVisible] = React.useState(false)
  const [paymentMethodNeededVisible, setPaymentMethodNeededVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const authContext = React.useContext(AuthContext)

  const isValid = dataConfirmed

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: tooltipVisible,
  } = usePopperTooltip({
    placement: `bottom-end`,
    offset: [0, 14],
    defaultVisible: false,
    trigger: `hover`,
  })

  React.useEffect(() => {
    setIsLoading(true)
    CmsTenant.getApprovedApplications()
      .then((applications) => {
        if (applications.length !== 1) navigate(`/`)
        setApplication(applications[0])
        return applications
      })
      .then((applications) => {
        // Ensure there's a payment method
        if (Array.isArray(applications) && applications.length > 0) {
          return CmsTenant.getPaymentMethods().then((paymentMethods) => {
            if (!Array.isArray(paymentMethods) || !paymentMethods.length) {
              setPaymentMethodNeededVisible(true)
            }
          })
        }
      })
      .catch((err) => {
        setErrorMessage(t(`Something went wrong`))
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  React.useEffect(() => {
    if (authContext.tenant === null) {
      authContext.loadTenant()
    } else {
      setProfile(authContext.tenant)
    }
  }, [authContext.tenant])

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    if (!isValid || isLoading) return
    setIsLoading(true)

    const updateData: UpdateApplication = {
      action: JSON.stringify({ action: ApplicationState.Finalized, text: `User confirmed application` }) as any,
    }
    if (visitDate?.length) updateData.roomReviewDate = visitDate

    CmsTenant.updateApplication(updateData)
      .then((response) => {
        if (response.state !== ApplicationState.Finalized) {
          setErrorMessage(t(`Something went wrong`))
        } else {
          setSuccessVisible(true)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <MainLayout className="signing-page">
      <Helmet title={t(`Signing`)} titleTemplate={props.data.site.siteMetadata.titleTemplate} />
      <Modal
        visible={successVisible}
        onClose={() => {
          navigate(`/`)
        }}
        placement="bottom"
      >
        <div className="auth-modal">
          <div className="auth-modal__content">
            <div className="container px-md-0 flex-column">
              <div className="d-flex flex-column align-items-center">
                <i className="auth-modal__success-icon icomoon icomoon-checkmark" />
                <div className="auth-modal__title mt-4">{t(`Information confirmed`)}</div>
                <div className="auth-modal__hint mt-2">{t(`signing_data_confirmed_message`)}</div>
                <button
                  className="auth-modal__main-button"
                  onClick={() => {
                    navigate(`/`)
                  }}
                >
                  {t(`Okay`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal visible={paymentMethodNeededVisible} placement="bottom" disableBackdropClose={true}>
        <div className="auth-modal">
          <div className="auth-modal__content">
            <div className="container px-md-0 flex-column">
              <div className="d-flex flex-column align-items-center">
                <div className="auth-modal__title mt-4">{t(`No payment method`)}</div>
                <div className="auth-modal__hint mt-2">{t(`signing_missing_payment_method`)}</div>
                <button
                  className="auth-modal__main-button"
                  onClick={() => {
                    const urlParams = new URLSearchParams({ do: `add-payment`, redirect: `/signing` })
                    navigate(`/account?${urlParams.toString()}#payment`, {})
                  }}
                >
                  {t(`Okay`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Header />

      <div className="horizontal-separator d-lg-none" />
      <div>
        <div className="signing__wrapper container-lg">
          <div className="signing__intro">
            <h2 className="signing__title ">{t(`Sign Contract`)}</h2>
            <div className="signing__text">{t(`signing_instructions`)}</div>
          </div>
          <h3 className="signing__subtitle">{t(`Room details`)}</h3>
          <div className="contract">
            <div className="contract__main-row row">
              <div className="contract_wrapper col">
                <div className="contract__section">
                  <div className="row">
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Name`)}</div>
                      <div className="contract__section-detail-value">
                        {CmsRoomTypes.getLocalName(application?.roomtype ?? null, language)}
                      </div>
                    </div>
                    {application?.roomtype?.priceData && (
                      <div className="contract__section-detail">
                        <div className="contract__section-detail-name">{t(`Monthly price`)}</div>
                        <div className="contract__section-detail-value">{application.roomtype.priceData.text}€</div>
                      </div>
                    )}
                    {application?.roomtype?.downpaymentData && (
                      <div className="contract__section-detail">
                        <div className="contract__section-detail-name">{t(`Downpayment`)}</div>
                        <div className="contract__section-detail-value">
                          {application.roomtype.downpaymentData.text}€
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className="signing__subtitle">{t(`Personal data`)}</h3>
          <div className="contract">
            <div className="contract__main-row row">
              <div className="contract_wrapper col">
                <div className="contract__section">
                  <div className="contract__section-title">{t(`Main data`)}</div>
                  <div className="row">
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Name`)}</div>
                      <div className="contract__section-detail-value">
                        {profile?.name} {profile?.surname}
                      </div>
                    </div>
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Date of birth`)}</div>
                      <div className="contract__section-detail-value">{profile?.birthDate}</div>
                    </div>
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Passport number`)}</div>
                      <div className="contract__section-detail-value">{profile?.passportNumber}</div>
                    </div>
                  </div>
                </div>
                <div className="contract__section">
                  <div className="contract__section-title">{t(`Contact data`)}</div>
                  <div className="row">
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Email`)}</div>
                      <div className="contract__section-detail-value">{profile?.email}</div>
                    </div>
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Phone`)}</div>
                      <div className="contract__section-detail-value">{profile?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="contract__section">
                  <div className="contract__section-title">{t(`Address`)}</div>
                  <div className="row">
                    <div className="contract__section-detail">
                      <div className="contract__section-detail-name">{t(`Address`)}</div>
                      <div className="contract__section-detail-value">
                        {profile?.address?.street}, {profile?.address?.zipCode}, {profile?.address?.city},{` `}
                        {profile?.address?.country}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="horizontal-separator" />

          <form onSubmit={handleSubmit}>
            <input
              id="contract_confirm_data_checkbox"
              type="checkbox"
              onChange={(event) => setDataConfirmed(event.target.checked)}
            />
            <label htmlFor="contract_confirm_data_checkbox">{t(`contract_data_correct_agreement`)}</label>
            <p className="signing__confirm-data-incorrect">
              {t(`If data is not correct, you can update it on`)}
              {` `}
              <Link to="/account">{t(`profile page`)}</Link>
            </p>

            <h3 className="signing__subtitle">{t(`Visiting details`)}</h3>
            <div className="signing__text">
              {t(`contract_signing_visited_question`)}
              <div className="signing__visited-info-icon" ref={setTriggerRef}>
                <InfoIcon />
              </div>
            </div>

            <div className="signing__visited">
              <div
                className={`signing__visited-option ${apartmentVisited ? `signing__visited-option--yes` : ``}`}
                onClick={() => setApartmentVisited(true)}
              >
                <input
                  type="radio"
                  id="contract_visited_checkbox_yes"
                  name="contract_visited_checkbox"
                  checked={apartmentVisited}
                  onChange={() => setApartmentVisited(true)}
                  hidden
                />
                <label htmlFor="contract_visited_checkbox_yes">{t(`Yes`)}</label>
              </div>

              <div
                className={`signing__visited-option ${!apartmentVisited ? `signing__visited-option--no` : ``}`}
                onClick={() => setApartmentVisited(false)}
              >
                <input
                  type="radio"
                  id="contract_visited_checkbox_no"
                  name="contract_visited_checkbox"
                  checked={!apartmentVisited}
                  onChange={() => setApartmentVisited(false)}
                  hidden
                />
                <label htmlFor="contract_visited_checkbox_no">{t(`No`)}</label>
              </div>
            </div>

            {apartmentVisited && (
              <div className="date-field ">
                <label>{t(`Date of visit`)}</label>
                <div>
                  <DatePicker value={visitDate} onChange={setVisitDate} placeholderText="" isVisitDate />
                </div>
              </div>
            )}

            <div className="horizontal-separator" />
            {errorMessage && <Alert variant={AlertVariant.Error}>{errorMessage}</Alert>}
            <LoadingButton
              className={`signing__submit ${isValid ? `` : `signing__submit--disabled`}`}
              type="submit"
              loading={isLoading}
            >
              {t(`Continue`)}
            </LoadingButton>
          </form>
        </div>
        {tooltipVisible && (
          <div className="signing__visited-info-text" ref={setTooltipRef} {...getTooltipProps()}>
            {t(`contract_signing_visited_disclaimer`)}
          </div>
        )}
      </div>

      <Footer />
    </MainLayout>
  )
}
export default SigningPage
